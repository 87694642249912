import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../../models/user/user';

export interface UserState {
    user: IUser;
    isAuthenticated: boolean;
}

const initialState: UserState = {
  user: {
    userId: '',
    fullName: '',
    email: '',
    profileImage: '',
    role: '',
    token: {
      accessToken: '',
      refreshToken: '',
      signature: ''
    }
  },
  isAuthenticated: false
};

// create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setUser: (state, action: { payload: any }) => {
      state.user.userId = action.payload.userId ? action.payload.userId : state.user.userId;
      state.user.fullName = action.payload.fullName ? action.payload.fullName : state.user.fullName;
      state.user.email = action.payload.email ? action.payload.email : state.user.email;
      state.user.profileImage = action.payload.profileImage ? action.payload.profileImage : state.user.profileImage;
      state.user.role = action.payload.role ? action.payload.role : state.user.role;
      state.user.token = action.payload.token ? action.payload.token : state.user.token;
    },
    logOutUser: (state) => {
      state.user = {
        userId: '',
        fullName: '',
        email: '',
        profileImage: '',
        role: '',
        token: {
          accessToken: '',
          refreshToken: '',
          signature: ''
        }
      };
      state.isAuthenticated = false;
    }

  }
});

// export the slice
export const { signInUser, logOutUser, setUser } = userSlice.actions;
export default userSlice.reducer;
