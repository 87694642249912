// @ts-ignore
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import outlinePhotoCamera from '@iconify/icons-ic/outline-photo-camera';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import MediumEditor from 'medium-editor';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';

import './form.css';
// material
import {
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  FormLabel,
  Alert,
  AlertTitle,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import IArticle from '../../../models/articles/Article';

import { useAppSelector } from '../../../app/redux/hooks';
import CreateArticleApi from '../../../services/articles/createArticleApi';
import UpdateArticleApi from '../../../services/articles/updateArticle';

// ----------------------------------------------------------------------

export default function BlogForm ({ blog } : { blog: undefined | IArticle }) {
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [articleError, setArticleError] = useState({ show: false, title: '', message: '' });

  const [coverImageUrl, setcoverImageUrl] = useState(blog?.coverImage || '');
  const [coverImageFile, setCoverImageFile] = useState<File | null>(null);

  const RegisterSchema = Yup.object().shape({
    coverImage: Yup.mixed().required('Cover Image is required'),
    title: Yup.string().required('Article Title is required'),
    subtitle: Yup.string().required('Article Subtitle is required'),
    status: Yup.string().required('Article Status is required'),
    content: Yup.string().required('Article Content is required')
  });

  const formik = useFormik({
    initialValues: {
      coverImage: blog?.coverImage || null,
      title: blog?.title || '',
      subtitle: blog?.subtitle || '',
      status: blog?.status || '',
      content: blog?.content || ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (formData) => {
      setArticleError({ show: false, title: '', message: '' });

      if (blog === undefined && coverImageFile) {
        const payload = new FormData();
        payload.append('file', coverImageFile, coverImageFile.name);
        payload.append('title', formData.title);
        payload.append('subtitle', formData.subtitle);
        payload.append('status', formData.status);
        payload.append('content', formData.content);
        payload.append('author', user.userId);

        const createArticleState = await CreateArticleApi({ payload });

        if (createArticleState && createArticleState.status) {
          alert('Article Created');
          console.log(createArticleState.data);
          navigate(`/dashboard/blogs/edit/${createArticleState.data.id}`, { replace: true });
          return;
        }

        if (createArticleState.errors) {
          setArticleError({ show: true, title: createArticleState.errors[0].param, message: createArticleState.errors[0].msg });
          return;
        }

        setArticleError({ show: true, title: 'Error', message: 'An error occurred. Try again!' });
        return;
      }

      const updateArticlePayload = {
        id: blog?.id,
        coverImage: blog?.coverImage || coverImageUrl,
        title: formData.title,
        subtitle: formData.subtitle,
        content: formData.content,
        status: formData.status,
        author: user.userId
      };

      const updateArticleState = await UpdateArticleApi({ articleId: typeof blog?.id === 'string' ? blog.id : '', payload: updateArticlePayload });

      if (updateArticleState && updateArticleState.status) {
        alert('Article Saved');
        console.log(updateArticleState.data);
        return;
      }

      if (updateArticleState.errors) {
        setArticleError({ show: true, title: updateArticleState.errors[0].param, message: updateArticleState.errors[0].msg });
        return;
      }

      setArticleError({ show: true, title: 'Error', message: 'An error occurred. Try again!' });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    const editor = new MediumEditor('.medium-textarea', {
      autoLink: true,
      delay: 1000,
      targetBlank: true,
      toolbar: {
        buttons: [
          'bold',
          'italic',
          'quote',
          'underline',
          'anchor',
          'orderedlist',
          'unorderedlist',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'strikethrough',
          'subscript',
          'superscript',
          'pre',
          'image',
          'html',
          'justifyCenter'
        ],
        diffLeft: 25,
        diffTop: 10
      },
      anchor: {
        placeholderText: 'Type a link',
        customClassOption: 'btn',
        customClassOptionText: 'Create Button'
      },
      paste: {
        cleanPastedHTML: true,
        cleanAttrs: ['style', 'dir'],
        cleanTags: ['label', 'meta'],
        unwrapTags: ['sub', 'sup']
      },
      anchorPreview: {
        hideDelay: 300
      },
      placeholder: {
        text: 'Write your story...',
        hideOnClick: true
      }
    });

    editor.setContent(blog?.content || '', 0);

    editor.subscribe('editableInput', (_ev, _editable) => {
      setFieldValue('content', editor.getContent(0));
    });
  }, []);

  const setImageSrcFromImageFile = (imageFile: File, setImageSrc: (src: any) => void) => {
    if (/\.(jpe?g|png|gif|tiff|svg|ico|webp)$/i.test(imageFile.name)) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          if (reader.readyState === 2) {
            const image = typeof reader.result === 'string' ? reader.result : '';
            setImageSrc(image);
          }
        },
        false
      );

      if (imageFile) {
        reader.readAsDataURL(imageFile);
      }
    }
  };

  const handleCoverImageInputChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      setFieldValue('coverImage', file);
      setCoverImageFile(file);

      setImageSrcFromImageFile(file, setcoverImageUrl);
    }
  };

  return (
    <FormikProvider value={formik}>
    {
      articleError.show && (
        <Alert severity="error" style={{ marginBottom: 30 }} variant="filled" onClose={() => setArticleError({ show: false, title: '', message: '' })}>
          <AlertTitle>{articleError.title}</AlertTitle>
          {articleError.message}
        </Alert>
      )
    }
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>

            <FormControl fullWidth>
              <FormLabel error={Boolean(touched.coverImage && errors.coverImage)}>Cover Image</FormLabel>
              <div className="auth_image">
                { coverImageUrl.length > 1 && <img src={coverImageUrl} alt="Cover Image" /> }
                <input onChange={e => handleCoverImageInputChange(e)} type="file" accept="image/*" id="cover_iamge_input" className={'image_input'} />
                <label className="label_auth_input" htmlFor="cover_iamge_input">
                  <Icon icon={outlinePhotoCamera} className="label_icon" />
                </label>
              </div>
              <FormHelperText error={Boolean(touched.coverImage && errors.coverImage)}>{touched.coverImage && errors.coverImage}</FormHelperText>
            </FormControl>

            <TextField
            fullWidth
            select
            label="Status"
            placeholder='Choose status of the article'
            {...getFieldProps('status')}
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && errors.status}
          >
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="published">Published</MenuItem>
          </TextField>

          <TextField
            fullWidth
            type="text"
            multiline={true}
            rows={2}
            label="Article Title"
            placeholder="Enter title of the article"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />

          <TextField
            fullWidth
            type="text"
            multiline={true}
            rows={3}
            label="Subtitle"
            placeholder="Enter a short description of the article"
            {...getFieldProps('subtitle')}
            error={Boolean(touched.subtitle && errors.subtitle)}
            helperText={touched.subtitle && errors.subtitle}
          />

          <FormControl fullWidth>
            <textarea className='medium-textarea editor' placeholder="Write your story.." onChange={(ev) => setFieldValue('content', ev.target.value)}>{blog?.content || ''}</textarea>
            <FormHelperText error={Boolean(touched.content && errors.content)}>{touched.content && errors.content}</FormHelperText>
          </FormControl>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save Article
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
