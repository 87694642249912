import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import BackIcon from '@iconify/icons-eva/arrow-back-fill';

import { useParams, useNavigate } from 'react-router-dom';

// import '../../assets/styles/medium.module.css';
import '../../assets/styles/article.css';
import 'medium-editor/dist/css/themes/default.css';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Button, Container, Typography } from '@mui/material';

// components
import Page from '../../components/styled-components/Page';

import IArticle from '../../models/articles/Article';

import FetchArticleByIdApi from '../../services/articles/fetchArticleByIdApi';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const WrapperContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const APPBAR_MOBILE = 65;
const APPBAR_DESKTOP = 55;

const AppBarStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function ArticleView () {
  const navigate = useNavigate();
  // const user = useAppSelector((state) => state.user.user);
  const [article, setArticle] = useState<IArticle>();
  const [loading, setLoading] = useState(true);

  const articleId = useParams().id;

  useEffect(() => {
    const getArticle = async () => {
      if (articleId === undefined) {
        setLoading(false);
        return;
      }

      setLoading(true);

      const fetchArticle = await FetchArticleByIdApi(articleId);

      if (fetchArticle.status && typeof fetchArticle.data === 'object') {
        setArticle(fetchArticle.data);
      }

      setLoading(false);
    };

    getArticle();
  }, [articleId]);

  return (
    // @ts-ignore
    <RootStyle title="Wakaafrica Article">
      <WrapperContainer>
        <Container>
          <AppBarStyle>
            <ToolbarStyle>
              <Button onClick={() => navigate('/dashboard/blogs', { replace: true })} variant="text" color="secondary" startIcon={<Icon icon={BackIcon} />}>Back</Button>

              <Box sx={{ flexGrow: 1 }} />
            </ToolbarStyle>
          </AppBarStyle>

        </Container>

        {
            (loading === false && typeof article === 'object')
              ? (
                  <div id="main-post" className="container-fluid main-container article-container">
                    <div className="article-cover-image">
                      <img src={ article.coverImage } alt="feature img 540" />
                    </div>
                    <div className="main-content">
                        <h3 className="article-title">
                            { article.title }
                        </h3>
                        <h5 className="article-subtitle">
                            { article.subtitle }
                        </h5>
                        <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
                    </div>
                  </div>
                )
              : (
                  loading === true
                    ? (
                <Typography sx={{ mt: 10 }} variant="subtitle1">Loading...</Typography>
                      )
                    : (
                <Typography sx={{ mt: 10 }} variant="h5">Article Not Found</Typography>
                      )
                )
        }
      </WrapperContainer>
    </RootStyle>
  );
}
