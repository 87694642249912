const constituencies = [
  {
    id: '60f0af5a9d08c91e109695d2',
    name: 'changamwe',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b13a53b7bd2f2ca8ce00',
    name: 'jomvu',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b14e53b7bd2f2ca8ce04',
    name: 'kisauni',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b15853b7bd2f2ca8ce08',
    name: 'nyali',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b16253b7bd2f2ca8ce0c',
    name: 'likoni',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b1ceca855b1b38aa25f3',
    name: 'mvita',
    countyId: '60f05826a26f650268a1f7c2'
  },
  {
    id: '60f0b210ca855b1b38aa25f9',
    name: 'msambweni',
    countyId: '60f058dea26f650268a1f7c5'
  },
  {
    id: '60f0b21aca855b1b38aa25fd',
    name: 'lunga lunga',
    countyId: '60f058dea26f650268a1f7c5'
  },
  {
    id: '60f0b227ca855b1b38aa2601',
    name: 'matuga',
    countyId: '60f058dea26f650268a1f7c5'
  },
  {
    id: '60f0b238ca855b1b38aa2605',
    name: 'kinango',
    countyId: '60f058dea26f650268a1f7c5'
  },
  {
    id: '60f0b258ca855b1b38aa2609',
    name: 'kilifi north',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b273ca855b1b38aa260d',
    name: 'kilifi south',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b284ca855b1b38aa2613',
    name: 'kaloleni',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b290ca855b1b38aa2617',
    name: 'rabai',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b2a1ca855b1b38aa261b',
    name: 'ganze',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b2adca855b1b38aa261f',
    name: 'malindi',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b2b9ca855b1b38aa2623',
    name: 'magarini',
    countyId: '60f05901a26f650268a1f7c8'
  },
  {
    id: '60f0b2dcca855b1b38aa2627',
    name: 'garsen',
    countyId: '60f05948a26f650268a1f7cb'
  },
  {
    id: '60f0b2e9ca855b1b38aa262b',
    name: 'galole',
    countyId: '60f05948a26f650268a1f7cb'
  },
  {
    id: '60f0b2f3ca855b1b38aa262f',
    name: 'bura',
    countyId: '60f05948a26f650268a1f7cb'
  },
  {
    id: '60f0b30aca855b1b38aa2633',
    name: 'lamu east',
    countyId: '60f05960a26f650268a1f7ce'
  },
  {
    id: '60f0b337ca855b1b38aa2638',
    name: 'lamu west',
    countyId: '60f05960a26f650268a1f7ce'
  },
  {
    id: '60f0b357ca855b1b38aa263c',
    name: 'taveta',
    countyId: '60f05985a26f650268a1f7d1'
  },
  {
    id: '60f0b365ca855b1b38aa2640',
    name: 'wundanyi',
    countyId: '60f05985a26f650268a1f7d1'
  },
  {
    id: '60f0b371ca855b1b38aa2644',
    name: 'mwatate',
    countyId: '60f05985a26f650268a1f7d1'
  },
  {
    id: '60f0b37bca855b1b38aa2648',
    name: 'voi',
    countyId: '60f05985a26f650268a1f7d1'
  },
  {
    id: '60f0b3adca855b1b38aa264c',
    name: 'garissa township',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3bfca855b1b38aa2652',
    name: 'balambala',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3c8ca855b1b38aa2656',
    name: 'lagdera',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3d1ca855b1b38aa265a',
    name: 'dadaab',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3dbca855b1b38aa265e',
    name: 'fafi',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3e7ca855b1b38aa2662',
    name: 'ijara',
    countyId: '60f059a9a26f650268a1f7d4'
  },
  {
    id: '60f0b3ffca855b1b38aa2666',
    name: 'wajir north',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b406ca855b1b38aa266a',
    name: 'wajir east',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b410ca855b1b38aa266e',
    name: 'tarbaj',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b41dca855b1b38aa2672',
    name: 'wajir west',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b428ca855b1b38aa2676',
    name: 'eldas',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b431ca855b1b38aa267a',
    name: 'wajir south',
    countyId: '60f059c7a26f650268a1f7d7'
  },
  {
    id: '60f0b446ca855b1b38aa267e',
    name: 'mandera west',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b451ca855b1b38aa2682',
    name: 'banissa',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b45bca855b1b38aa2686',
    name: 'mandera north',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b465ca855b1b38aa268a',
    name: 'mandera south',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b473ca855b1b38aa268e',
    name: 'mandera east',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b47fca855b1b38aa2692',
    name: 'lafey',
    countyId: '60f06354a26f650268a1f7dd'
  },
  {
    id: '60f0b4f4ca855b1b38aa2696',
    name: 'moyale',
    countyId: '60f06594a26f650268a1f7e3'
  },
  {
    id: '60f0b506ca855b1b38aa269c',
    name: 'north horr',
    countyId: '60f06594a26f650268a1f7e3'
  },
  {
    id: '60f0b512ca855b1b38aa26a0',
    name: 'saku',
    countyId: '60f06594a26f650268a1f7e3'
  },
  {
    id: '60f0b51cca855b1b38aa26a4',
    name: 'laisamis',
    countyId: '60f06594a26f650268a1f7e3'
  },
  {
    id: '60f0b535ca855b1b38aa26a8',
    name: 'isiolo north',
    countyId: '60f06a73a26f650268a1f7e9'
  },
  {
    id: '60f0b546ca855b1b38aa26ac',
    name: 'isiolo south',
    countyId: '60f06a73a26f650268a1f7e9'
  },
  {
    id: '60f0b55bca855b1b38aa26b0',
    name: 'igembe south',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b56fca855b1b38aa26b6',
    name: 'igembe central',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b57aca855b1b38aa26ba',
    name: 'igembe north',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b583ca855b1b38aa26be',
    name: 'tigania west',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b58fca855b1b38aa26c2',
    name: 'tigania east',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b59aca855b1b38aa26c6',
    name: 'north imenti',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b5a5ca855b1b38aa26ca',
    name: 'buuri',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b5b2ca855b1b38aa26ce',
    name: 'central imenti',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b5bfca855b1b38aa26d2',
    name: 'south imenti',
    countyId: '60f06aaaa26f650268a1f7ee'
  },
  {
    id: '60f0b5dcca855b1b38aa26d6',
    name: 'maara',
    countyId: '60f06ac3a26f650268a1f7f1'
  },
  {
    id: '60f0b5efca855b1b38aa26da',
    name: "chuka/igambang'ombe",
    countyId: '60f06ac3a26f650268a1f7f1'
  },
  {
    id: '60f0b657ca855b1b38aa26de',
    name: 'tharaka',
    countyId: '60f06ac3a26f650268a1f7f1'
  },
  {
    id: '60f0b66eca855b1b38aa26e2',
    name: 'manyatta',
    countyId: '60f06af5a26f650268a1f7f7'
  },
  {
    id: '60f0b679ca855b1b38aa26e6',
    name: 'runyenjes',
    countyId: '60f06af5a26f650268a1f7f7'
  },
  {
    id: '60f0b685ca855b1b38aa26ea',
    name: 'mbeere south',
    countyId: '60f06af5a26f650268a1f7f7'
  },
  {
    id: '60f0b68fca855b1b38aa26ee',
    name: 'mbeere north',
    countyId: '60f06af5a26f650268a1f7f7'
  },
  {
    id: '60f0b6a6ca855b1b38aa26f2',
    name: 'mwingi north',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6b0ca855b1b38aa26f6',
    name: 'mwingi west',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6c4ca855b1b38aa26fa',
    name: 'mwingi central',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6cfca855b1b38aa26fe',
    name: 'kitui west',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6daca855b1b38aa2702',
    name: 'kitui rural',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6e4ca855b1b38aa2706',
    name: 'kitui central',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6f0ca855b1b38aa270a',
    name: 'kitui east',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b6fcca855b1b38aa270e',
    name: 'kitui south',
    countyId: '60f06b2ba26f650268a1f7fa'
  },
  {
    id: '60f0b732ca855b1b38aa2712',
    name: 'masinga',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b73dca855b1b38aa2716',
    name: 'yatta',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b749ca855b1b38aa271a',
    name: 'kangundo',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b754ca855b1b38aa271e',
    name: 'matungulu',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b75fca855b1b38aa2722',
    name: 'kathiani',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b768ca855b1b38aa2726',
    name: 'mavoko',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b772ca855b1b38aa272a',
    name: 'machakos town',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b77cca855b1b38aa272e',
    name: 'mwala',
    countyId: '60f06b5da26f650268a1f7fd'
  },
  {
    id: '60f0b791ca855b1b38aa2732',
    name: 'mbooni',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b799ca855b1b38aa2736',
    name: 'kilome',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b7a2ca855b1b38aa273a',
    name: 'kaiti',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b7abca855b1b38aa273e',
    name: 'makueni',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b7b4ca855b1b38aa2742',
    name: 'kibwezi west',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b7beca855b1b38aa2746',
    name: 'kibwezi east',
    countyId: '60f06b69a26f650268a1f800'
  },
  {
    id: '60f0b7e6ca855b1b38aa274a',
    name: 'kinangop',
    countyId: '60f06b84a26f650268a1f803'
  },
  {
    id: '60f0b832ca855b1b38aa274e',
    name: 'kipipiri',
    countyId: '60f06b84a26f650268a1f803'
  },
  {
    id: '60f0b83bca855b1b38aa2752',
    name: 'ol kalou',
    countyId: '60f06b84a26f650268a1f803'
  },
  {
    id: '60f0b845ca855b1b38aa2756',
    name: 'ol jorok',
    countyId: '60f06b84a26f650268a1f803'
  },
  {
    id: '60f0b852ca855b1b38aa275a',
    name: 'ndaragwa',
    countyId: '60f06b84a26f650268a1f803'
  },
  {
    id: '60f0b867ca855b1b38aa275e',
    name: 'tetu',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b872ca855b1b38aa2762',
    name: 'kieni',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b892ca855b1b38aa2766',
    name: 'mathira',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b89aca855b1b38aa276a',
    name: 'othaya',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b8a9ca855b1b38aa276e',
    name: 'mukurweini',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b8b6ca855b1b38aa2772',
    name: 'nyeri town',
    countyId: '60f06b93a26f650268a1f806'
  },
  {
    id: '60f0b953ca855b1b38aa2776',
    name: 'mwea',
    countyId: '60f06bb5a26f650268a1f80b'
  },
  {
    id: '60f0b96dca855b1b38aa277a',
    name: 'gichugu',
    countyId: '60f06bb5a26f650268a1f80b'
  },
  {
    id: '60f0b976ca855b1b38aa277e',
    name: 'ndia',
    countyId: '60f06bb5a26f650268a1f80b'
  },
  {
    id: '60f0b983ca855b1b38aa2782',
    name: 'kirinyaga central',
    countyId: '60f06bb5a26f650268a1f80b'
  },
  {
    id: '60f0b999ca855b1b38aa2786',
    name: 'kangema',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9a3ca855b1b38aa278a',
    name: 'mathioya',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9abca855b1b38aa278e',
    name: 'kiharu',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9b4ca855b1b38aa2792',
    name: 'kigumo',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9c5ca855b1b38aa2796',
    name: 'maragwa',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9ceca855b1b38aa279a',
    name: 'kandara',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9deca855b1b38aa279e',
    name: 'gatanga',
    countyId: '60f06be5a26f650268a1f80e'
  },
  {
    id: '60f0b9fcca855b1b38aa27a2',
    name: 'gatundu south',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba08ca855b1b38aa27a6',
    name: 'gatundu north',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba13ca855b1b38aa27aa',
    name: 'juja',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba1dca855b1b38aa27ae',
    name: 'thika town',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba27ca855b1b38aa27b2',
    name: 'ruiru',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba38ca855b1b38aa27b6',
    name: 'githunguri',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba43ca855b1b38aa27ba',
    name: 'kiambu',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba4dca855b1b38aa27be',
    name: 'kiambaa',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba58ca855b1b38aa27c2',
    name: 'kabete',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba64ca855b1b38aa27c6',
    name: 'kikuyu',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba6fca855b1b38aa27ca',
    name: 'limuru',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0ba7bca855b1b38aa27ce',
    name: 'lari',
    countyId: '60f06bf2a26f650268a1f811'
  },
  {
    id: '60f0baadca855b1b38aa27d2',
    name: 'turkana north',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0bab7ca855b1b38aa27d6',
    name: 'turkana west',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0bac6ca855b1b38aa27da',
    name: 'turkana central',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0bad0ca855b1b38aa27de',
    name: 'loima',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0badaca855b1b38aa27e2',
    name: 'turkana south',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0bae5ca855b1b38aa27e6',
    name: 'turkana east',
    countyId: '60f06c38a26f650268a1f814'
  },
  {
    id: '60f0bc065103d52e8c76c583',
    name: 'kapenguria',
    countyId: '60f06c4ca26f650268a1f81a'
  },
  {
    id: '60f0bc5e74a8da3390672931',
    name: 'sigor',
    countyId: '60f06c4ca26f650268a1f81a'
  },
  {
    id: '60f0bc7274a8da3390672935',
    name: 'kacheliba',
    countyId: '60f06c4ca26f650268a1f81a'
  },
  {
    id: '60f0bc7b74a8da3390672939',
    name: 'pokot south',
    countyId: '60f06c4ca26f650268a1f81a'
  },
  {
    id: '60f0bc8f74a8da339067293d',
    name: 'samburu west',
    countyId: '60f06c5ea26f650268a1f81d'
  },
  {
    id: '60f0bc9c74a8da3390672941',
    name: 'samburu north',
    countyId: '60f06c5ea26f650268a1f81d'
  },
  {
    id: '60f0bca674a8da3390672945',
    name: 'samburu east',
    countyId: '60f06c5ea26f650268a1f81d'
  },
  {
    id: '60f0bcba74a8da3390672949',
    name: 'kwanza',
    countyId: '60f06c77a26f650268a1f820'
  },
  {
    id: '60f0bccd74a8da339067294d',
    name: 'endebess',
    countyId: '60f06c77a26f650268a1f820'
  },
  {
    id: '60f0bcd674a8da3390672951',
    name: 'saboti',
    countyId: '60f06c77a26f650268a1f820'
  },
  {
    id: '60f0bce374a8da3390672955',
    name: 'kiminini',
    countyId: '60f06c77a26f650268a1f820'
  },
  {
    id: '60f0bcee74a8da3390672959',
    name: 'cherangany',
    countyId: '60f06c77a26f650268a1f820'
  },
  {
    id: '60f0bcff74a8da339067295d',
    name: 'soy',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd1e74a8da3390672961',
    name: 'turbo',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd2774a8da3390672965',
    name: 'moiben',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd3074a8da3390672969',
    name: 'ainabkoi',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd3974a8da339067296d',
    name: 'kapseret',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd4474a8da3390672971',
    name: 'kesses',
    countyId: '60f06c8aa26f650268a1f823'
  },
  {
    id: '60f0bd5974a8da3390672975',
    name: 'marakwet east',
    countyId: '60f06ca3a26f650268a1f826'
  },
  {
    id: '60f0bd6574a8da3390672979',
    name: 'marakwet west',
    countyId: '60f06ca3a26f650268a1f826'
  },
  {
    id: '60f0bd6e74a8da339067297d',
    name: 'keiyo north',
    countyId: '60f06ca3a26f650268a1f826'
  },
  {
    id: '60f0bd7874a8da3390672981',
    name: 'keiyo south',
    countyId: '60f06ca3a26f650268a1f826'
  },
  {
    id: '60f0bd9874a8da3390672985',
    name: 'tinderet',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0bda274a8da3390672989',
    name: 'aldai',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0bdb074a8da339067298d',
    name: 'nandi hills',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0bdb874a8da3390672991',
    name: 'chesumei',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0bdc974a8da3390672995',
    name: 'emgwen',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0bdd074a8da3390672999',
    name: 'mosop',
    countyId: '60f06cb4a26f650268a1f829'
  },
  {
    id: '60f0be6c74a8da339067299d',
    name: 'tiaty',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0be7374a8da33906729a1',
    name: 'baringo north',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0be7a74a8da33906729a5',
    name: 'baringo central',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0be8174a8da33906729a9',
    name: 'baringo south',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0be8774a8da33906729ad',
    name: 'mogotio',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0be8f74a8da33906729b1',
    name: 'eldama ravine',
    countyId: '60f06cc4a26f650268a1f82c'
  },
  {
    id: '60f0bea074a8da33906729b5',
    name: 'laikipia west',
    countyId: '60f06cdaa26f650268a1f82f'
  },
  {
    id: '60f0bea974a8da33906729b9',
    name: 'laikipia east',
    countyId: '60f06cdaa26f650268a1f82f'
  },
  {
    id: '60f0beb074a8da33906729bd',
    name: 'laikipia north',
    countyId: '60f06cdaa26f650268a1f82f'
  },
  {
    id: '60f0bec274a8da33906729c1',
    name: 'molo',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0beca74a8da33906729c5',
    name: 'njoro',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bed474a8da33906729c9',
    name: 'naivasha',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0beda74a8da33906729cd',
    name: 'gilgil',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bee174a8da33906729d1',
    name: 'kuresoi south',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0beea74a8da33906729d5',
    name: 'kuresoi north',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bef174a8da33906729d9',
    name: 'subukia',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bef974a8da33906729dd',
    name: 'rongai',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0befe74a8da33906729e1',
    name: 'bahati',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bf0674a8da33906729e5',
    name: 'nakuru town west',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bf1174a8da33906729e9',
    name: 'nakuru town east',
    countyId: '60f06ce5a26f650268a1f832'
  },
  {
    id: '60f0bf2674a8da33906729ed',
    name: 'kilgoris',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf3274a8da33906729f1',
    name: 'emurua dikirr',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf3a74a8da33906729f5',
    name: 'narok north',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf4174a8da33906729f9',
    name: 'narok east',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf4874a8da33906729fd',
    name: 'narok south',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf4f74a8da3390672a01',
    name: 'narok west',
    countyId: '60f06cf0a26f650268a1f835'
  },
  {
    id: '60f0bf6c74a8da3390672a05',
    name: 'kajiado north',
    countyId: '60f06cfea26f650268a1f838'
  },
  {
    id: '60f0bf7374a8da3390672a09',
    name: 'kajiado central',
    countyId: '60f06cfea26f650268a1f838'
  },
  {
    id: '60f0bf7874a8da3390672a0d',
    name: 'kajiado east',
    countyId: '60f06cfea26f650268a1f838'
  },
  {
    id: '60f0bf7f74a8da3390672a11',
    name: 'kajiado west',
    countyId: '60f06cfea26f650268a1f838'
  },
  {
    id: '60f0bf8574a8da3390672a15',
    name: 'kajiado south',
    countyId: '60f06cfea26f650268a1f838'
  },
  {
    id: '60f0bf9d74a8da3390672a19',
    name: 'kipkelion east',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfa474a8da3390672a1d',
    name: 'kipkelion west',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfab74a8da3390672a21',
    name: 'ainamoi',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfb174a8da3390672a25',
    name: 'bureti',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfb974a8da3390672a29',
    name: 'belgut',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfc374a8da3390672a2d',
    name: 'sigowet soin',
    countyId: '60f06d13a26f650268a1f83b'
  },
  {
    id: '60f0bfd574a8da3390672a31',
    name: 'sotik',
    countyId: '60f06d1fa26f650268a1f83e'
  },
  {
    id: '60f0bfdc74a8da3390672a35',
    name: 'chepalungu',
    countyId: '60f06d1fa26f650268a1f83e'
  },
  {
    id: '60f0bfe174a8da3390672a39',
    name: 'bomet east',
    countyId: '60f06d1fa26f650268a1f83e'
  },
  {
    id: '60f0bfe774a8da3390672a3d',
    name: 'bomet central',
    countyId: '60f06d1fa26f650268a1f83e'
  },
  {
    id: '60f0bfed74a8da3390672a41',
    name: 'konoin',
    countyId: '60f06d1fa26f650268a1f83e'
  },
  {
    id: '60f0bffc74a8da3390672a45',
    name: 'lugari',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c00b74a8da3390672a49',
    name: 'likuyani',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c02074a8da3390672a4d',
    name: 'malava',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c02574a8da3390672a51',
    name: 'lurambi',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c02a74a8da3390672a55',
    name: 'navakholo',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c03274a8da3390672a59',
    name: 'mumias west',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c03874a8da3390672a5d',
    name: 'mumias east',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c03f74a8da3390672a61',
    name: 'matungu',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c04774a8da3390672a65',
    name: 'butere',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c04d74a8da3390672a69',
    name: 'khwisero',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c05474a8da3390672a6d',
    name: 'shinyalu',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c05b74a8da3390672a71',
    name: 'ikolomani',
    countyId: '60f06d8ca26f650268a1f845'
  },
  {
    id: '60f0c09174a8da3390672a75',
    name: 'vihiga',
    countyId: '60f06d9ca26f650268a1f848'
  },
  {
    id: '60f0c09774a8da3390672a79',
    name: 'sabatia',
    countyId: '60f06d9ca26f650268a1f848'
  },
  {
    id: '60f0c09d74a8da3390672a7d',
    name: 'hamisi',
    countyId: '60f06d9ca26f650268a1f848'
  },
  {
    id: '60f0c0a274a8da3390672a81',
    name: 'luanda',
    countyId: '60f06d9ca26f650268a1f848'
  },
  {
    id: '60f0c0aa74a8da3390672a85',
    name: 'emuhaya',
    countyId: '60f06d9ca26f650268a1f848'
  },
  {
    id: '60f0c0c874a8da3390672a89',
    name: 'mount elgon',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c0d074a8da3390672a8d',
    name: 'sirisia',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c0d674a8da3390672a91',
    name: 'kabuchai',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c0ea74a8da3390672a97',
    name: 'bumula',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c10574a8da3390672a9b',
    name: 'kanduyi',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c10c74a8da3390672a9f',
    name: 'webuye east',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c11274a8da3390672aa3',
    name: 'webuye west',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c11a74a8da3390672aa7',
    name: 'kimilili',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c12074a8da3390672aab',
    name: 'tongaren',
    countyId: '60f06da9a26f650268a1f84b'
  },
  {
    id: '60f0c13374a8da3390672aaf',
    name: 'teso north',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c13b74a8da3390672ab3',
    name: 'teso south',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c14274a8da3390672ab7',
    name: 'nambale',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c14774a8da3390672abb',
    name: 'matayos',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c14d74a8da3390672abf',
    name: 'butula',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c15374a8da3390672ac3',
    name: 'funyula',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c15974a8da3390672ac7',
    name: 'budalangi',
    countyId: '60f06db6a26f650268a1f84e'
  },
  {
    id: '60f0c16874a8da3390672acb',
    name: 'ugenya',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c17374a8da3390672acf',
    name: 'ugunja',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c17974a8da3390672ad3',
    name: 'alego usonga',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c18174a8da3390672ad7',
    name: 'gem',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c18774a8da3390672adb',
    name: 'bondo',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c18f74a8da3390672adf',
    name: 'rarieda',
    countyId: '60f06dfaa26f650268a1f856'
  },
  {
    id: '60f0c1a674a8da3390672ae3',
    name: 'kisumu east',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c1ad74a8da3390672ae7',
    name: 'kisumu west',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c1e774a8da3390672aeb',
    name: 'kisumu central',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c1ed74a8da3390672aef',
    name: 'seme',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c1f474a8da3390672af3',
    name: 'nyando',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c1f974a8da3390672af7',
    name: 'muhoroni',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c20174a8da3390672afb',
    name: 'nyakach',
    countyId: '60f06e0aa26f650268a1f859'
  },
  {
    id: '60f0c22474a8da3390672aff',
    name: 'kasipul',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c22e74a8da3390672b03',
    name: 'kabondo kasipul',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c23474a8da3390672b07',
    name: 'karachuonyo',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c24474a8da3390672b0b',
    name: 'rangwe',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c24c74a8da3390672b0f',
    name: 'homa bay town',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c25474a8da3390672b13',
    name: 'ndhiwa',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c25a74a8da3390672b17',
    name: 'mbita',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c26474a8da3390672b1b',
    name: 'suba',
    countyId: '60f06e17a26f650268a1f85c'
  },
  {
    id: '60f0c28674a8da3390672b1f',
    name: 'rongo',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c28d74a8da3390672b23',
    name: 'awendo',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c29374a8da3390672b27',
    name: 'suna east',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c29974a8da3390672b2b',
    name: 'suna west',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c2a374a8da3390672b2f',
    name: 'uriri',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c2a874a8da3390672b33',
    name: 'nyatike',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c2ae74a8da3390672b37',
    name: 'kuria west',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c2b574a8da3390672b3b',
    name: 'kuria east',
    countyId: '60f06e25a26f650268a1f85f'
  },
  {
    id: '60f0c2c674a8da3390672b3f',
    name: 'bonchari',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2cc74a8da3390672b43',
    name: 'south mugirango',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2d474a8da3390672b47',
    name: 'bomachoge borabu',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2da74a8da3390672b4b',
    name: 'bobasi',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2e174a8da3390672b4f',
    name: 'bomachoge chache',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2ec74a8da3390672b53',
    name: 'nyaribari masaba',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c2f474a8da3390672b57',
    name: 'nyaribari chache',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c30274a8da3390672b5b',
    name: 'kitutu chache north',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c30a74a8da3390672b5f',
    name: 'kitutu chache south',
    countyId: '60f06e32a26f650268a1f862'
  },
  {
    id: '60f0c31c74a8da3390672b63',
    name: 'kitutu masaba',
    countyId: '60f06e3fa26f650268a1f865'
  },
  {
    id: '60f0c32374a8da3390672b67',
    name: 'west mugirango',
    countyId: '60f06e3fa26f650268a1f865'
  },
  {
    id: '60f0c32c74a8da3390672b6b',
    name: 'north mugirango',
    countyId: '60f06e3fa26f650268a1f865'
  },
  {
    id: '60f0c33274a8da3390672b6f',
    name: 'borabu',
    countyId: '60f06e3fa26f650268a1f865'
  },
  {
    id: '60f0c35474a8da3390672b73',
    name: 'westlands',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c35a74a8da3390672b77',
    name: 'dagoretti north',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c36174a8da3390672b7b',
    name: 'dagoretti south',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c36874a8da3390672b7f',
    name: "lang'ata",
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c36f74a8da3390672b83',
    name: 'kibra',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c37574a8da3390672b87',
    name: 'roysambu',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c37c74a8da3390672b8b',
    name: 'kasarani',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c38374a8da3390672b8f',
    name: 'ruaraka',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c38974a8da3390672b93',
    name: 'embakasi south',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c39d74a8da3390672b97',
    name: 'embakasi north',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3a374a8da3390672b9b',
    name: 'embakasi central',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3a974a8da3390672b9f',
    name: 'embakasi east',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3af74a8da3390672ba3',
    name: 'embakasi west',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3b574a8da3390672ba7',
    name: 'makadara',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3bc74a8da3390672bab',
    name: 'kamukunji',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3c174a8da3390672baf',
    name: 'starehe',
    countyId: '60f06e7ba26f650268a1f86d'
  },
  {
    id: '60f0c3c974a8da3390672bb3',
    name: 'mathare',
    countyId: '60f06e7ba26f650268a1f86d'
  }
];

export default constituencies;
