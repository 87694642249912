import axios from 'axios';

const backendUrl = `https://wakaafrica.org/api`;
// const backendUrl = `http://localhost:6900/api`;

const apiInstance = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export default apiInstance;
