import { styled } from '@mui/material/styles';
import { Button, Box, Avatar, Card, Typography, CardContent } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../app/redux/hooks';

const CardMediaStyle = styled('div')({
  width: '100%',
  aspectRatio: '1 / 1'
});

const CardImageStyle = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

export default function ProfilePhotoCard () {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  return (
        <Card>
          <CardContent>
            <CardMediaStyle>
              {
                user.profileImage
                  ? (
                  <CardImageStyle src={user.profileImage} alt="Profile Image" />
                    )
                  : (
                  <Avatar variant="rounded" sx={{ width: '100%', height: '100%', backgroundColor: 'Background' }} alt="Profile Image" />
                    )
              }
            </CardMediaStyle>
            <Box p={2}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>{user.firstName} {user.lastName}</Typography>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>{user.memberNumber}</Typography>
            </Box>
            <Box sx={{ pt: 1.5 }}>
              <Button fullWidth color="inherit" variant="outlined" onClick={() => navigate('/app/profile/edit')}>
                Edit Profile
              </Button>
            </Box>
          </CardContent>
        </Card>
  );
}
