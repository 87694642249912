import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  AlertTitle,
  AlertColor
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppSelector } from '../../app/redux/hooks';

import ChangePasswordApi from '../../services/profile/ChangePasswordApi';
// ----------------------------------------------------------------------

interface IChangePasswordState {
  show: boolean,
  title: string,
  message: string,
  type: AlertColor
}

export default function ChangePasswordForm () {
  const user = useAppSelector((state) => state.user.user);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const [changePasswordState, setChangePasswordState] = useState<IChangePasswordState>({ show: false, title: '', message: '', type: 'success' });

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().min(8, 'Old Password must be at least 8 characters!').required('Old Password is required!'),
    newPassword: Yup.string().min(8, 'New Password must be at least 8 characters!').required('New Password is required!'),
    confPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match!').required('Confirm password is required!')
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confPassword: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (passwordData) => {
      setChangePasswordState({ show: false, title: '', message: '', type: 'success' });

      const changePasswordPayload = {
        clientId: user.clientId,
        payload: passwordData
      };

      const passwordApiState = await ChangePasswordApi(changePasswordPayload);

      if (passwordApiState && passwordApiState.status) {
        setChangePasswordState({ show: true, title: 'Success', message: 'Password updated successfully', type: 'success' });
        return;
      }

      if (passwordApiState.errors) {
        setChangePasswordState({ show: true, title: passwordApiState.errors[0].param, message: passwordApiState.errors[0].msg, type: 'error' });
        return;
      }

      setChangePasswordState({ show: true, title: 'Error', message: 'An error occurred. Try again!', type: 'error' });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {
        changePasswordState.show && (
          <Alert severity={changePasswordState.type} style={{ marginBottom: 30 }} variant="filled" onClose={() => setChangePasswordState({ show: false, title: '', message: '', type: 'success' })}>
            <AlertTitle>{changePasswordState.title}</AlertTitle>
            {changePasswordState.message}
          </Alert>
        )
      }
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="password"
            type={showOldPassword ? 'text' : 'password'}
            label="Old Password"
            {...getFieldProps('oldPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowOldPassword((prev) => !prev)}>
                    <Icon icon={showOldPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowNewPassword((prev) => !prev)}>
                    <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type={showConfPassword ? 'text' : 'password'}
            label="Confirm New Password"
            {...getFieldProps('confPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowConfPassword((prev) => !prev)}>
                    <Icon icon={showConfPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confPassword && errors.confPassword)}
            helperText={touched.confPassword && errors.confPassword}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Change Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
