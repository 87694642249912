import { AxiosResponse, AxiosError } from 'axios';
import axios from '../../app/requests/axios';
import IApiResponse from '../../models/requests/apiResponse';

interface IDeleteArticle {
    msg: string
}

const DeleteArticleApi = async (articleId: string): Promise<IApiResponse<IDeleteArticle>> => {
  return await axios.delete(`/articles/${articleId}`)
    .then((response: AxiosResponse<IApiResponse<IDeleteArticle>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        return error.response.data;
      }
      return {
        status: false,
        code: '0',
        errors: [{
          param: 'Network Error',
          msg: 'Check your network connection then try again!'
        }
        ]
      };
    }
    );
};

export default DeleteArticleApi;
