// Psge to display profile data
import { styled } from '@mui/material/styles';
import { Box, Card, Typography, CardContent } from '@mui/material';

import { useAppSelector } from '../../app/redux/hooks';

export default function ProfileDataCard () {
  const user = useAppSelector((state) => state.user.user);

  return (
        <Card>
          <CardContent>
              <Box pb={2} sx={{ borderBottom: '1px solid', borderColor: 'gray.200' }}>
                <Typography variant="h4">Profile Details</Typography>
              </Box>
            <Box py={2}>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Phone Number</Typography>
                <Typography variant="body1">{user.phoneNumber}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Email</Typography>
                <Typography variant="body1">{user.email}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Gender</Typography>
                <Typography variant="body1">{user.gender}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Date of Birth</Typography>
                <Typography variant="body1">{new Date(user.dateOfBirth).toLocaleDateString()}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">County</Typography>
                <Typography variant="body1">{user.county}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Constituency</Typography>
                <Typography variant="body1">{user.constituency}</Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography variant="subtitle1">Phone Number 2</Typography>
                <Typography variant="body1">{user.phoneNumber2}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
  );
}
