import { AxiosResponse, AxiosError } from 'axios';
import axios from '../../app/requests/axios';
import { IUser, IUserToken } from '../../models/user/user';
import IApiResponse from '../../models/requests/apiResponse';

interface ILoginPayload {
    userIdentifier: string;
    password: string;
}

interface ILoginResponseUserData {
    user: IUser;
    token: IUserToken;
}

const loginApi = async (loginPayload: ILoginPayload): Promise<IApiResponse<ILoginResponseUserData>> => {
  return await axios.post('/users/login', loginPayload)
    .then((response: AxiosResponse<IApiResponse<ILoginResponseUserData>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        return error.response.data;
      }
      return {
        status: false,
        code: '0',
        errors: [{
          param: 'Network Error',
          msg: 'Check your network connection then try again!'
        }
        ]
      };
    }
    );
};

export default loginApi;
