import { AxiosResponse, AxiosError } from 'axios';
import axios from '../../app/requests/axios';
import IApiResponse from '../../models/requests/apiResponse';
import IArticle from '../../models/articles/Article';

interface IArticlePayload {
    payload: any
};

const CreateArticleApi = async (articlePayload: IArticlePayload): Promise<IApiResponse<IArticle>> => {
  return await axios.post('/articles', articlePayload.payload, {
    headers: {
      accept: 'application/json',
      'Content-Type': 'multipart/form-data;'
    }
  })
    .then((response: AxiosResponse<IApiResponse<IArticle>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        return error.response.data;
      }
      return {
        status: false,
        code: '0',
        errors: [{
          param: 'Network Error',
          msg: 'Check your network connection then try again!'
        }
        ]
      };
    }
    );
};

export default CreateArticleApi;
