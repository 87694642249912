// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../../components/styled-components/Page';
import { ChangePasswordForm } from '../../components/profile';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2.5, 0)
}));

// ----------------------------------------------------------------------

export default function ChangePassword () {
  return (
    // @ts-ignore
    <RootStyle title="Change Password | Freelancers Hub">

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 2.5 }}>
            <Typography variant="h4" gutterBottom>
              Change Password
            </Typography>
          </Stack>

          <ChangePasswordForm />

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
