import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@mui/material/styles';
import {
  Stack,
  TextField,
  Avatar,
  Badge,
  IconButton,
  MenuItem,
  Alert,
  AlertTitle,
  AlertColor
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import cameraIcon from '@iconify/icons-eva/camera-fill';

import ImageFileToImageSrc from '../../utils/fileToImageSrc';

import { useAppDispatch, useAppSelector } from '../../app/redux/hooks';

import { setUser } from '../../app/redux/features/user/userSlice';

import EditProfileApi from 'src/services/profile/EditProfileApi';

import counties from '../../utils/location/counties';
import constituencies from '../../utils/location/constituencies';

// ----------------------------------------------------------------------

counties.sort((countyA, countyB) => countyA.name.localeCompare(countyB.name));
constituencies.sort((constituencyA, constituencyB) => constituencyA.name.localeCompare(constituencyB.name));

const StyledFileInput = styled('input')({
  display: 'none'
});

interface IEditProfileState {
  show: boolean,
  title: string,
  message: string,
  type: AlertColor
}

// fullName: '',
// email: '',
// profileImage: '',

export default function EditProfileForm () {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [profileImage, setProfileImage] = useState(user.profileImage);

  const [editProfileState, setEditProfileState] = useState<IEditProfileState>({ show: false, title: '', message: '', type: 'success' });

  const RegisterSchema = Yup.object().shape({
    // profileImage: Yup.string().required('Profile Image is required'),
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name is required!'),
    phoneNumber: Yup.string().min(10, 'Enter valid Phone Number!').max(10, 'Enter valid Phone Number!').required('Phone number is required!'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required!')
  });

  const formik = useFormik({
    initialValues: {
      profileImage: user.profileImage,
      profileImageFile: null,
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      email: user.email
    },
    validationSchema: RegisterSchema,
    onSubmit: async (profileData) => {
      setEditProfileState({ show: false, title: '', message: '', type: 'success' });

      profileData.profileImage = profileData.profileImage ? profileData.profileImage : 'http://placeimg.com/640/480';

      const profilePayload = {
        clientId: user.clientId,
        payload: profileData
      };

      const profileApiState = await EditProfileApi(profilePayload);

      if (profileApiState && profileApiState.status) {
        setEditProfileState({ show: true, title: 'Success', message: 'Profile updated successfully', type: 'success' });
        dispatch(setUser(profileData));
        return;
      }

      if (profileApiState.errors) {
        setEditProfileState({ show: true, title: profileApiState.errors[0].param, message: profileApiState.errors[0].msg, type: 'error' });
        return;
      }

      setEditProfileState({ show: true, title: 'Error', message: 'An error occurred. Try again!', type: 'error' });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const openFileSelector = () => {
    fileInputRef.current?.click();
  };

  const handleProfileImageInputChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      setFieldValue('profileImageFile', file);

      ImageFileToImageSrc(file, setProfileImage);
    }
  };

  return (
    <FormikProvider value={formik}>
      {
        editProfileState.show && (
          <Alert severity={editProfileState.type} style={{ marginBottom: 30 }} variant="filled" onClose={() => setEditProfileState({ show: false, title: '', message: '', type: 'success' })}>
            <AlertTitle>{editProfileState.title}</AlertTitle>
            {editProfileState.message}
          </Alert>
        )
      }
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Badge
            sx={{ width: 120, alignSelf: 'center' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton onClick={openFileSelector} style={{ width: 40, height: 40, backgroundColor: 'ActiveBorder', border: '1px solid', borderColor: 'Background' }}>
                <Icon icon={cameraIcon} style={{ backgroundColor: 'ActiveBorder', color: 'Background' }} />
              </IconButton>
            }
            >
            <Avatar src={profileImage} style={{ width: 120, height: 120 }} />
            <StyledFileInput ref={fileInputRef} type="file" accept="image/*" id="profileImage" onChange={handleProfileImageInputChange} />
        </Badge>
            <TextField
              fullWidth
              label="Full Name"
              {...getFieldProps('fullName')}
              error={Boolean(touched.fullName && errors.fullName)}
              helperText={touched.fullName && errors.fullName}
            />

          <TextField
            fullWidth
            autoComplete="tel"
            type="tel"
            label="Phone Number"
            placeholder="07xxxxxxxx"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
