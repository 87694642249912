import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography
} from '@mui/material';
// components
import Page from '../../components/styled-components/Page';
// import Label from '../components/Label';
//
import USERLIST from '../../_mocks_/user';

// ----------------------------------------------------------------------

export default function User () {
  return (
    // @ts-ignore
    <Page title="Users | Wakaafrica Dashboard">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Typography sx={{ mt: 1 }} variant="subtitle2">No Users</Typography>
      </Container>
    </Page>
  );
}
