// material
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/styled-components/Page';

// ----------------------------------------------------------------------

export default function Members () {
  return (
    // @ts-ignore
    <Page title="Members | Wakaafrica Dashboard">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Members
          </Typography>
        </Stack>

        <Typography sx={{ mt: 1 }} variant="subtitle2">No Members</Typography>
      </Container>
    </Page>
  );
}
