import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo ({ sx } : { sx : any}) {
  return <Box component="img" src="/static/logo/wakaafrica-white-lg.png" sx={{ width: 130, height: 45, ...sx }} />;
}
