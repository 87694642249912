import { Icon } from '@iconify/react';
import piechartFill from '@iconify/icons-eva/pie-chart-2-fill';
import eventsFill from '@iconify/icons-ic/round-outbox';
import membersFill from '@iconify/icons-ic/people';
import blogsFill from '@iconify/icons-eva/file-text-fill';
import messagesFill from '@iconify/icons-ic/mail';
import usersFill from '@iconify/icons-ant-design/robot-filled';

// ----------------------------------------------------------------------

const getIcon = (name: any) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Overview',
    path: '/dashboard/overview',
    icon: getIcon(piechartFill)
  },
  {
    title: 'Blogs',
    path: '/dashboard/blogs',
    icon: getIcon(blogsFill)
  },
  {
    title: 'Members',
    path: '/dashboard/members',
    icon: getIcon(membersFill)
  },
  {
    title: 'Events',
    path: '/dashboard/events',
    icon: getIcon(eventsFill)
  },
  {
    title: 'Messages',
    path: '/dashboard/messages',
    icon: getIcon(messagesFill)
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: getIcon(usersFill)
  }
];

export default sidebarConfig;
