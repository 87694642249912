import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';

import { useAppSelector } from './app/redux/hooks';

// Pages
import Login from './pages/Auth/Login';

import Overview from './pages/Overview/Overview';
import Articles from './pages/Articles/Articles';
import AddArticle from './pages/Articles/AddArticles';
import ArticleView from './pages/Articles/ArticleView';
import Events from './pages/Events/Events';
import AddEvent from './pages/Events/AddEvent';
import Users from './pages/Users/Users';
import AddUser from './pages/Users/AddUser';
import Messages from './pages/Messages/Messages';
import Members from './pages/Members/Members';

import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';
import ChangePassword from './pages/Profile/ChangePassword';

import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

const AuthRoutes = () => {
  return useRoutes([
    { path: '/', element: <Login /> },
    { path: 'login', element: <Login /> },
    { path: '*', element: <Navigate to="/"/> }
  ]);
};

const DashboardRoutes = () => {
  return useRoutes([
    { path: '/', element: <Navigate to="/dashboard/blogs" replace /> },
    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        { path: 'overview', element: <Overview /> },

        { path: 'blogs', element: <Articles /> },

        { path: 'events', element: <Events /> },
        { path: 'events/add', element: <AddEvent/> },

        { path: 'members', element: <Members /> },

        { path: 'messages', element: <Messages /> },

        { path: 'users', element: <Users /> },
        { path: 'users/add', element: <AddUser /> },

        { path: 'profile', element: <Profile /> },
        { path: 'profile/edit', element: <EditProfile /> },
        { path: 'profile/change-password', element: <ChangePassword /> },

        { path: '404', element: <NotFound /> },

        { path: '*', element: <Navigate to="/dashboard/404" /> }
      ]
    },
    { path: '/dashboard/article/:id', element: <ArticleView /> },
    { path: '/dashboard/blogs/add', element: <AddArticle /> },
    { path: '/dashboard/blogs/edit/:id', element: <AddArticle /> },
    { path: '*', element: <Navigate to="/dashboard/blogs" /> }
  ]);
};

export default function Router () {
  const user = useAppSelector((state) => state.user);

  const AppRoutes = () => {
    if (user.isAuthenticated) {
      return <DashboardRoutes />;
    }

    return <AuthRoutes />;
  };

  return useRoutes([
    { path: '//*', element: <AppRoutes /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
