import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/styled-components/Page';

// ----------------------------------------------------------------------

export default function Events () {
  return (
    // @ts-ignore
    <Page title="Events | Wakaafrica Dashboard">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Events
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Icon icon={plusFill} />}
          >
            New Event
          </Button> */}
        </Stack>

        <Typography sx={{ mt: 1 }} variant="subtitle2">No Events</Typography>
      </Container>
    </Page>
  );
}
