const counties = [
  { id: '60f05826a26f650268a1f7c2', name: 'mombasa' },
  { id: '60f058dea26f650268a1f7c5', name: 'kwale' },
  { id: '60f05901a26f650268a1f7c8', name: 'kilifi' },
  { id: '60f05948a26f650268a1f7cb', name: 'tana river' },
  { id: '60f05960a26f650268a1f7ce', name: 'lamu' },
  { id: '60f05985a26f650268a1f7d1', name: 'taita taveta' },
  { id: '60f059a9a26f650268a1f7d4', name: 'garissa' },
  { id: '60f059c7a26f650268a1f7d7', name: 'wajir' },
  { id: '60f06354a26f650268a1f7dd', name: 'mandera' },
  { id: '60f06594a26f650268a1f7e3', name: 'marsabit' },
  { id: '60f06a73a26f650268a1f7e9', name: 'isiolo' },
  { id: '60f06aaaa26f650268a1f7ee', name: 'meru' },
  { id: '60f06ac3a26f650268a1f7f1', name: 'tharaka nithi' },
  { id: '60f06af5a26f650268a1f7f7', name: 'embu' },
  { id: '60f06b2ba26f650268a1f7fa', name: 'kitui' },
  { id: '60f06b5da26f650268a1f7fd', name: 'machakos' },
  { id: '60f06b69a26f650268a1f800', name: 'makueni' },
  { id: '60f06b84a26f650268a1f803', name: 'nyandarua' },
  { id: '60f06b93a26f650268a1f806', name: 'nyeri' },
  { id: '60f06bb5a26f650268a1f80b', name: 'kirinyaga' },
  { id: '60f06be5a26f650268a1f80e', name: 'murang’a' },
  { id: '60f06bf2a26f650268a1f811', name: 'kiambu' },
  { id: '60f06c38a26f650268a1f814', name: 'turkana' },
  { id: '60f06c4ca26f650268a1f81a', name: 'west pokot' },
  { id: '60f06c5ea26f650268a1f81d', name: 'samburu' },
  { id: '60f06c77a26f650268a1f820', name: 'trans nzoia' },
  { id: '60f06c8aa26f650268a1f823', name: 'uasin gishu' },
  { id: '60f06ca3a26f650268a1f826', name: 'elgeyo marakwet' },
  { id: '60f06cb4a26f650268a1f829', name: 'nandi' },
  { id: '60f06cc4a26f650268a1f82c', name: 'baringo' },
  { id: '60f06cdaa26f650268a1f82f', name: 'laikipia' },
  { id: '60f06ce5a26f650268a1f832', name: 'nakuru' },
  { id: '60f06cf0a26f650268a1f835', name: 'narok' },
  { id: '60f06cfea26f650268a1f838', name: 'kajiado' },
  { id: '60f06d13a26f650268a1f83b', name: 'kericho' },
  { id: '60f06d1fa26f650268a1f83e', name: 'bomet' },
  { id: '60f06d8ca26f650268a1f845', name: 'kakamega' },
  { id: '60f06d9ca26f650268a1f848', name: 'vihiga' },
  { id: '60f06da9a26f650268a1f84b', name: 'bungoma' },
  { id: '60f06db6a26f650268a1f84e', name: 'busia' },
  { id: '60f06dfaa26f650268a1f856', name: 'siaya' },
  { id: '60f06e0aa26f650268a1f859', name: 'kisumu' },
  { id: '60f06e17a26f650268a1f85c', name: 'homa bay' },
  { id: '60f06e25a26f650268a1f85f', name: 'migori' },
  { id: '60f06e32a26f650268a1f862', name: 'kisii' },
  { id: '60f06e3fa26f650268a1f865', name: 'nyamira' },
  { id: '60f06e7ba26f650268a1f86d', name: 'nairobi' }
];

export default counties;
