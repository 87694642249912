// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../../components/styled-components/Page';
import { ProfilePhotoCard, ProfileInfoCard } from '../../components/profile';

// ----------------------------------------------------------------------

export default function Profile () {
  return (
    // @ts-ignore
    <Page title="Profile | Freelancers Hub">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <ProfilePhotoCard />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ProfileInfoCard />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
