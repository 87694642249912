import { AxiosResponse, AxiosError } from 'axios';
import axios from '../../app/requests/axios';
import IApiResponse from '../../models/requests/apiResponse';

interface IEditProfileResponseData {
    msg: string;
};

interface IProfilePayload {
    clientId: string;
    payload: {
        profileImage: string;
        fullName: string;
        phoneNumber: string;
        email: string;
    }
};

const EditProfileApi = async (profilePayload: IProfilePayload): Promise<IApiResponse<IEditProfileResponseData>> => {
  return await axios.patch(`/clients/profile/${profilePayload.clientId}`, profilePayload.payload)
    .then((response: AxiosResponse<IApiResponse<IEditProfileResponseData>>) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        return error.response.data;
      }
      return {
        status: false,
        code: '0',
        errors: [{
          param: 'Network Error',
          msg: 'Check your network connection then try again!'
        }
        ]
      };
    }
    );
};

export default EditProfileApi;
