import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  AlertTitle
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppDispatch } from '../../../app/redux/hooks';
import { signInUser } from '../../../app/redux/features/user/userSlice';

import loginApi from '../../../services/auth/LoginApi';

// ----------------------------------------------------------------------

export default function LoginForm () {
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState({ show: false, title: '', message: '' });

  const LoginSchema = Yup.object().shape({
    userIdentifier: Yup.string().required('Email is required'),
    password: Yup.string().trim().min(8, 'Password must be at least 8 characters').required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      userIdentifier: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (loginData) => {
      setLoginError({ show: false, title: '', message: '' });

      const loginApiState = await loginApi(loginData);

      if (loginApiState && loginApiState.status) {
        const userData = {
          ...loginApiState.data.user,
          token: loginApiState.data.token
        };

        dispatch(signInUser(userData));
        return;
      }

      if (loginApiState.errors) {
        setLoginError({ show: true, title: loginApiState.errors[0].param, message: loginApiState.errors[0].msg });
        return;
      }

      setLoginError({ show: true, title: 'Error', message: 'An error occurred. Try again!' });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      {
        loginError.show && (
          <Alert severity="error" style={{ marginBottom: 30 }} variant="filled" onClose={() => setLoginError({ show: false, title: '', message: '' })}>
            <AlertTitle>{loginError.title}</AlertTitle>
            {loginError.message}
          </Alert>
        )
      }
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mb: 5 }}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            {...getFieldProps('userIdentifier')}
            error={Boolean(touched.userIdentifier && errors.userIdentifier)}
            helperText={touched.userIdentifier && errors.userIdentifier}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
