import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/styled-components/Page';
import { BlogPostCard } from '../../components/articles';
//
// import POSTS from '../../_mocks_/blog';
import IArticle from '../../models/articles/Article';
import FetchAllArticlesApi from '../../services/articles/fetchAllArticlesApi';

// ----------------------------------------------------------------------

export default function Articles () {
  const [articles, setArticles] = useState<IArticle[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArticles = async () => {
      setLoading(true);

      const fetchArticles = await FetchAllArticlesApi();

      if (fetchArticles.status && typeof fetchArticles.data === 'object') {
        setArticles(fetchArticles.data);
      }

      setLoading(false);
    };

    getArticles();
  }, []);

  return (
    // @ts-ignore
    <Page title="Blogs | Wakaafrica Dashboard">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blogs
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Icon icon={plusFill} />}
          >
            New Article
          </Button>
        </Stack>
        {
          (loading === false && typeof articles === 'object' && articles?.length > 0)
            ? (
            <Grid container spacing={3}>
              {articles.map((post, index) => (
                <BlogPostCard key={post.id} post={post} index={index} />
              ))}
            </Grid>
              )
            : (
                loading === true
                  ? (
              <Typography variant="subtitle1">Loading...</Typography>
                    )
                  : (
              <Typography variant="h5">No Articles</Typography>
                    )
              )
        }
      </Container>
    </Page>
  );
}
