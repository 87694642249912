import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trashCan from '@iconify/icons-eva/trash-outline';
import editPen from '@iconify/icons-eva/edit-fill';
import copyIcon from '@iconify/icons-eva/copy-outline';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// material
import { alpha, styled } from '@mui/material/styles';
import { Link, Card, Grid, Avatar, Typography, CardContent, Button } from '@mui/material';
// utils
import { fDate } from '../../utils/formatTime';
//
import SvgIconStyle from '../styled-components/SvgIconStyle';

import IArticle from '../../models/articles/Article';
import DeleteArticleApi from '../../services/articles/deleteArticleApi';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 2 / 3)'
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

// const TitleStyle = styled('a')({
//   height: 44,
//   marginBottom: 10,
//   overflow: 'hidden',
//   WebkitLineClamp: 2,
//   display: '-webkit-box',
//   WebkitBoxOrient: 'vertical'
// });

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const StatusTextStyle = styled('div')({
  width: '100%',
  display: 'flex'
});

const ActionsContainerStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 350,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3)
}));

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function BlogPostCard ({ post, index }: { post : IArticle, index : any}) {
  const navigate = useNavigate();

  const [linkCopied, setLinkCopied] = useState(false);
  const { coverImage, title, subtitle, status, author, publishDate, publicUrl } = post;
  const latestPost = index === 0;

  const articleUrl = `https://wakaafrica.org/article/${publicUrl}`;
  // const articleUrl = `http://localhost:6900/article/${publicUrl}`;

  const copyArticleUrl = () => {
    navigator.clipboard.writeText(articleUrl);
    setLinkCopied(true);
  };

  const deleteArticle = async () => {
    if (!confirm('Are you sure you want to delete this article?')) return;

    const deleteStatus = await DeleteArticleApi(post.id);

    if (deleteStatus.status) {
      alert('Article deleted Successfully');
      navigate('/dashboard/blogs', { replace: true });
    } else {
      alert('An error occured!');
    }
  };

  return (
    <Grid item xs={12} sm={latestPost ? 12 : 6} md={latestPost ? 12 : 4}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            ...((latestPost) && {
              pt: 'calc(100% * 1 / 2)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(latestPost && {
              pt: {
                xs: 'calc(100% * 3 / 2)',
                sm: 'calc(100% * 2 / 3)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              ...(latestPost && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt={author.fullName}
            src={author.profileImage}
            sx={{
              ...(latestPost && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt={title} src={coverImage} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            ...(latestPost && {
              bottom: 0,
              width: '100%',
              position: 'absolute'
            })
          }}
        >

          <StatusTextStyle>
            <Typography
              gutterBottom
              variant="caption"
              sx={{ color: status === 'published' ? 'primary.main' : 'text.primary', display: 'block', textTransform: 'capitalize', fontWeight: '600' }}
            >
              {status}
            </Typography>
            {
              status === 'published' && (
                <Typography
                  gutterBottom
                  variant="caption"
                  sx={{ color: 'text.disabled', display: 'block', marginLeft: 0 }}
                >
                  {' - '}{fDate(publishDate)}
                </Typography>
              )
            }
          </StatusTextStyle>

          <TitleStyle
            // href={articleUrl}
            // color="inherit"
            // target="_blank"
            // @ts-ignore
            to={`/dashboard/article/${post.id}`}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
            sx={{
              ...(latestPost && { typography: 'h5', height: 60 }),
              ...(latestPost && {
                color: 'common.white'
              })
            }}
          >
            {title}
          </TitleStyle>

          <Typography
            gutterBottom
            variant="body2"
            sx={{ color: latestPost ? 'background.default' : 'text.secondary', display: 'block', height: 90, overflow: 'hidden' }}
          >
            {subtitle}
          </Typography>

          <ActionsContainerStyle>
            {
              status === 'published' && (
                <Button variant="text" onClick={copyArticleUrl} sx={{ color: latestPost ? 'background.default' : 'grey.900' }}>
                  <Icon icon={copyIcon} style={{ marginRight: 5, color: latestPost ? 'background.default' : 'grey.900' }} />
                  <Typography variant="button" sx={{ color: linkCopied ? 'success.main' : latestPost ? 'background.default' : 'grey.900' }}>{ linkCopied ? 'Copied' : 'Copy Link' }</Typography>
                </Button>
              )
            }
            <Button variant="text" onClick={async () => await deleteArticle()} sx={{ color: 'error.main' }}>
              <Icon icon={trashCan} style={{ marginRight: 5, color: 'error.main' }} />
              <Typography variant="button">Delete</Typography>
            </Button>
            <Button variant="text" onClick={() => navigate(`/dashboard/blogs/edit/${post.id}`, { replace: true })} sx={{ color: 'primary.main' }}>
              <Icon icon={editPen} style={{ marginRight: 5, color: 'primary.main' }} />
              <Typography variant="button" >Edit</Typography>
            </Button>
          </ActionsContainerStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
