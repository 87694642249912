import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import BackIcon from '@iconify/icons-eva/arrow-back-fill';

import { useParams, useNavigate } from 'react-router-dom';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Button, Container, Typography, Stack } from '@mui/material';

// components
import Page from '../../components/styled-components/Page';
import { BlogForm } from '../../components/articles/addBlog';

import IArticle from '../../models/articles/Article';

// import { useAppSelector } from '../../app/redux/hooks';

import FetchArticleByIdApi from '../../services/articles/fetchArticleByIdApi';
import DeleteArticleApi from '../../services/articles/deleteArticleApi';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(10, 5),
  [theme.breakpoints.down('md')]: {
    paddingTop: 80,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const APPBAR_MOBILE = 50;
const APPBAR_DESKTOP = 70;

const AppBarStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function AddArticle () {
  const navigate = useNavigate();
  // const user = useAppSelector((state) => state.user.user);
  const [article, setArticle] = useState<IArticle>();
  const [loading, setLoading] = useState(true);

  const articleId = useParams().id;

  useEffect(() => {
    const getArticle = async () => {
      if (articleId === undefined) {
        setLoading(false);
        return;
      }

      setLoading(true);

      const fetchArticle = await FetchArticleByIdApi(articleId);

      if (fetchArticle.status && typeof fetchArticle.data === 'object') {
        setArticle(fetchArticle.data);
      }

      setLoading(false);
    };

    getArticle();
  }, [articleId]);

  const deleteArticle = async () => {
    if (!article?.id || !confirm('Are you sure you want to delete this article?')) return;

    const deleteStatus = await DeleteArticleApi(article.id);

    if (deleteStatus.status) {
      alert('Article deleted Successfully');
      navigate('/dashboard/blogs', { replace: true });
    } else {
      alert('An error occured!');
    }
  };

  return (
    // @ts-ignore
    <RootStyle title="Article | Wakaafrica Dashboard">

      <Container>
        <AppBarStyle>
          <ToolbarStyle>
            <Button onClick={() => navigate('/dashboard/blogs', { replace: true })} variant="text" color="secondary" startIcon={<Icon icon={BackIcon} />}>Back</Button>

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              {
                !loading && typeof article === 'object' && (
                  <Button variant="contained" onClick={async () => await deleteArticle()} color="error">Delete</Button>
                )
              }
                {/* <Button variant="contained" onClick={() => 1 + 1} color="error">Delete</Button> */}
                {/* <Button variant="contained" onClick={() => 1 + 1} color="primary">Save Article</Button> */}
            </Stack>
          </ToolbarStyle>
        </AppBarStyle>
        <ContentStyle>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              { !loading && typeof article === 'undefined' ? 'New Article' : 'Article' }
            </Typography>
          </Box>

          {
            loading
              ? (
              <Typography variant="subtitle1">Loading...</Typography>
                )
              : (
              <BlogForm blog={article} />
                )
          }

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
