// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// layouts
// components
import Page from '../../components/styled-components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 5)
}));

// ----------------------------------------------------------------------

export default function AddEvent () {
  return (
    // @ts-ignore
    <RootStyle title="New Event | Wakaafrica Dashboard">

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              New Event
            </Typography>

          </Box>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
