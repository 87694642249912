const imageFileToImageSrc = (imageFile: File, setImageSrc: (src: any) => void) => {
  if (/\.(jpe?g|png|gif|tiff|svg|ico|webp)$/i.test(imageFile.name)) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (reader.readyState === 2) {
          const image = typeof reader.result === 'string' ? reader.result : '';
          setImageSrc(image);
        }
      },
      false
    );

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  }
};

export default imageFileToImageSrc;
