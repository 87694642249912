// material
import { styled } from '@mui/material/styles';
import { Card, CardContent, Stack, Container, Typography, Box } from '@mui/material';

// components
import Page from '../../components/styled-components/Page';
import { LoginForm } from '../../components/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login () {
  return (
    // @ts-ignore
    <RootStyle title="Login | Wakaafrica Dashboard">

      <Container maxWidth="sm">
        <ContentStyle>
          <Card>
            <CardContent>
              <Stack sx={{ mb: 3, mt: 3 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box component="img" src="/static/logo/wakaafrica-lg.png" sx={{ width: 180, height: 65 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
              </Stack>
              <Stack sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in
                </Typography>
              </Stack>

              <LoginForm />

            </CardContent>
          </Card>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
