import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { randomBytes, createHash } from 'crypto';
import { combineReducers } from 'redux';
import userReducer from './features/user/userSlice';

const hashAlgorithm = (data: any) => {
  return `THIS${data}SECURE`;
};

const sessionToken = () => {
  const str = randomBytes(64).toString('hex');
  // file deepcode ignore InsecureHash: <please specify a reason of ignoring this>
  return createHash('sha256').update(str).digest('hex').toString();
};

// stores the redux data in local storage
export const saveState = (state: any) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
    localStorage.setItem(
      'stateToken',
      hashAlgorithm(JSON.stringify(state))
    );
    localStorage.setItem('session', sessionToken());
    // localStorage.setItem('hashed', hashAlgorithm(state.user.user.id));
    // localStorage.setItem('usertoken', state.user.user.token);
  } catch (e) {

  }
};

// fetches the redux data from local storage
const loadState = () => {
  const serialState = localStorage.getItem('state');
  if (serialState === null || undefined) {
    return undefined;
  }
  return JSON.parse(serialState);
};

// this is the persisted state from local storage
const persistedState = loadState();

const staticReducers = {
  user: userReducer
};

const initialStore = {
  ...persistedState
};

export const store = configureStore({
  reducer: combineReducers(staticReducers),
  preloadedState: initialStore,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
